/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "../../@core/scss/base/bootstrap-extended/include";
@import "../../@core/scss/base/components/include";

a {
  color: $dark;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: $primary;
}

/* Loader */

html .pace .pace-progress {
  background: $primary;
}

.word_break {
  word-break: break-word;
}

.all_projects_table_btn_div.message-btn {
  width: auto;
  margin-right: 5px;
}

.rnbloader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;

  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }

  color: $primary;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &:after {
    left: 3.5em;
  }
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* Loader */

//

/* Pagination */

.page-item {
  &.active .page-link {
    z-index: 3;
    border-radius: 5rem;
    background-color: $primary;
    color: #fff !important;
    font-weight: 600;
  }

  &.prev-item .page-link:hover,
  &.next-item .page-link:hover {
    background-color: $primary !important;
  }

  .page-link:hover {
    color: $primary;
  }
}

/* pagination */

/* Primary Button Css Start */

.btn {
  padding: 9px 15px;
}

.error {
  color: red;
}

.btn-primary {
  border-color: $primary !important;
  background-color: $primary !important;
  color: #000 !important;

  &.disabled,
  &:disabled {
    color: #b9b9c3 !important;
    background-color: #efefef !important;
    border-color: #efefef !important;
    cursor: default;
    pointer-events: none;
  }

  &:focus,
  &:active,
  &.active {
    color: #000;
    background-color: $primary !important;
  }

  &:hover:not(.disabled):not(:disabled),
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

/* Primary Button Css end */

/* secondary Button Css Start */

.btn-secondary {
  border-color: $primary !important;
  border: 1px solid $primary;
  background-color: #ffffff !important;
  color: #000 !important;

  &:focus,
  &:active,
  &.active {
    color: #000;
    background-color: $primary !important;
  }

  &:hover:not(.disabled):not(:disabled),
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

/* secondary Button Css end */

/* Drop Down start */

.btn-outline-primary {
  border: 1px solid $primary !important;
  background-color: transparent;
  color: #000;

  &:hover:not(.disabled):not(:disabled) {
    background-color: rgba(115, 103, 240, 0.04);
    color: #000;
  }

  &:not(:disabled):not(.disabled) {
    &:active {
      &:focus {
        box-shadow: none;
      }

      background-color: $primary;
      color: #000;
    }

    &.active,
    &:focus {
      background-color: $primary;
      color: #000;
    }
  }

  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  }
}

.show>.btn-outline-primary.dropdown-toggle {
  background-color: $primary;
  color: #000;
}

.btn-outline-primary.waves-effect .waves-ripple,
.btn-flat-primary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(115, 103, 240, 0.2) 0,
      rgba(115, 103, 240, 0.3) 40%,
      rgba(115, 103, 240, 0.4) 50%,
      rgba(115, 103, 240, 0.5) 60%,
      rgba(255, 255, 255, 0) 70%);
}

/* Drop Down end */

/* Date  */

.picker--focused .picker__day--highlighted {
  background-color: $primary;
}

/* Date */

/* Form Control */

.form-control {
  &:focus {
    border-color: $primary;
  }

  &:disabled {
    color: #222936;
  }
}

.input-group:not(.bootstrap-touchspin):focus-within {

  .form-control,
  .input-group-text {
    border-color: $primary;
  }
}

.select2-container--classic.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: $primary !important;
}

/* Form Control */

table.dataTable thead {

  .sorting::before,
  .sorting_asc::before,
  .sorting_desc::before {
    height: inherit !important;
    margin-bottom: 5px;
  }

  .sorting::after,
  .sorting_asc::after,
  .sorting_desc::after {
    height: inherit !important;
  }
}

/* header-top */

.header-top {
  display: block;
  height: 100px;
  background: url(../images/head-bg.png) no-repeat;
  z-index: 999;
  position: fixed;
  width: 100%;
  padding: 0 24px;
  background-position: center center;
}

.main-title {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 800;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0;
}

.user-login-info {
  color: #1a1a1a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: normal;
  margin: 0 15px;
  text-align: left;
  display: block;
}

.top-user-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    margin-top: 0 !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .avatar {
    background: #e7e8e9 !important;
    color: #222936 !important;

    .avatar-content {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }
}

.use-logout {
  margin: 0 0px 5px 6px;
}

.top-user-info {
  .navbar-nav {
    .nav-link {
      font-weight: 800;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      padding: 4.012px 0;
    }

    li.nav-item {
      margin-right: 15px;
    }
  }

  li.nav-item.active:after {
    content: " ";
    position: relative;
    background: #ffffff;
    width: 100%;
    height: 1px;
    z-index: 99;
    display: block;
  }
}

/* End  */
/* side bar main menu */

.main-menu {
  margin-top: 100px;
  overflow: visible;

  &.menu-light .navigation>li {
    ul .active {
      box-shadow: none;
      z-index: 1;
      background: $primary;
      border-radius: 6px;
      color: $dark;
      font-weight: 500;

      >a {
        color: #000;
      }
    }

    &.active>a {
      background: $primary;
      box-shadow: none;
      color: $dark;
      border-radius: 4px;
      font-weight: 500;

      svg path {
        /* fill: inherit; */
        stroke: inherit;
      }
    }
  }

  .navbar-header {
    height: auto;
  }
}

.user-chart {
  span {
    font-weight: 500;
    color: $dark;
  }
}

/*
  .main-menu.menu-light .navigation>li ul .active>a:hover {
	  background: $primary;
  } */

/* .navigation li .active {
	  background: $primary;
  } */

.vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content>li>a svg {
  margin-right: 1.38rem;
  height: auto;
  width: 15px;

  &.user_svg {
    margin-right: 1.38rem;
    height: auto;
    width: 20px;
  }
}

//
.vertical-layout .main-menu .navigation a {
  color: #625f6e;
  font-weight: 500;

  &.active {
    font-weight: 600;
    background: $primary;
    box-shadow: none;
    color: $dark;
    border-radius: 4px;
    // font-weight: 500;
  }
}

.nav-toggle-arrow {
  // position: absolute;
  // right: -12px;
  // z-index: 99;
  position: absolute;
  right: -35px;
  bottom: -36px;
  transform: rotate(0deg);
}

.navbar-header {
  & .modern-nav-toggle {
    position: relative;
    z-index: 99;
    padding: 0;
  }

  img {
    transform: rotate(0deg);
  }

  &.expanded img {
    transform: rotate(0deg);
  }
}

.main-menu ul.navigation-main {
  padding-bottom: 4rem;
}

/* end side menu */

.modal-css {
  max-width: 648px;
}

.modal-open .modal {
  z-index: 9999;
  background: rgb(0 0 0 / 48%);

  &.modal-slide-in {
    z-index: 99999;
  }
}

.modal {
  .modal-css {
    .modal-header {
      background-color: transparent;
      padding: 25px 15px;

      h4 {
        text-align: center;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: $dark;
      }
    }

    .modal-body {
      padding: 0 64px;

      label {
        color: #222936;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .modal-header .close {
      transform: translate(7px, -25px);

      &:hover,
      &:focus,
      &:active {
        transform: translate(7px, -23px);
      }
    }

    .modal-footer {
      justify-content: center;
      border-top: 0;
      padding: 30px 0;

      button {
        margin: 0 5px;
      }
    }
  }

  &.modal-slide-in .modal-header {
    background-color: #f8f8f8;
  }

  .modal-header {
    background-color: transparent;

    /* padding: 25px 15px; */
  }
}

/*common modal */

.forgot-modal .modal-header {
  padding: 25px 15px;

  &.otp-modal {
    padding: 25px 15px 5px;
  }
}

.modal {
  .modal-footer.add_plant {
    justify-content: flex-end;
  }

  .modal-header h4 {
    text-align: center;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: $dark;
  }

  .modal-body label {
    color: #222936;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  .modal-header .close {
    transform: translate(7px, -25px);

    &:hover,
    &:focus,
    &:active {
      transform: translate(7px, -23px);
    }
  }

  .modal-footer {
    justify-content: center;
    border-top: 0;
    padding: 30px 0;

    button {
      margin: 0 5px;
    }
  }
}

/* .modal .modal-body {
	  padding: 0 64px;
  } */

/* end common modal */

/* side menu end */

.gujarati-bg {
  background: rgba(184, 239, 239, 0.3);
  color: #000000;

  &:focus {
    background: rgba(184, 239, 239, 0.3);
    color: #000000;
  }
}

/* Government users table */

.btn_search_box {
  display: flex;
  justify-content: space-between;
  padding: 10px 9px;

  /* padding: 20px; */
  /* padding-bottom: 0; */

  .form-control:focus {
    border-color: $primary;
  }
}

.page-item.active .page-link {
  background-color: $primary;
}

.dropdown-menu {
  min-width: 15rem;
}

.table-title {
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: $dark;

    /* margin-top: 18px; */
    text-transform: uppercase;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #58595b;
    margin-bottom: 20px;
  }
}

.table {
  .thead-light th {
    border-color: rgb(169 224 224);
    border-bottom: 1px solid rgb(169 224 224);
  }

  &:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
    background-color: #b8efef;
    color: #222936;
    text-align: center;
  }
}

tr.total_count {
  font-weight: bold;
  color: #000;
}

table.dataTable {

  td,
  th {
    padding: 0.5rem 0.5rem;
  }
}

.card .table tbody td {
  /* padding: 19px 17px 20px 16px; */
  padding: 7px 17px 14px 11px !important;
  text-align: center;

  &:first-child {
    font-weight: 600;
    font-size: 14px;
    color: $dark;
  }
}

.add-new {
  background: $primary !important;
  border-radius: 5px !important;
  border-color: $primary !important;
  color: #000 !important;
}

.action_icons {
  display: flex;
  justify-content: space-between;
}

.col-12.bookmark-col .b-link {
  border: 2px solid #dfdfdf;
  border-radius: 20px;
  padding: 20px;

  /* margin: 2px; */
}

.Others {
  svg {
    path {
      fill: #fff;
    }
  }
}

.Others .card .card-header {
  flex-wrap: inherit;
  cursor: pointer;

  &:hover {
    color: $purple;
  }
}

.table th {
  padding: 13px 16px;
}

.action_icons.others {
  display: block;

  svg:first-child {
    margin: 0 10px 0 0;
  }
}

/* Location */

.location .card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $dark;
}

.bk-list {
  padding: 10px 9px;

  &.selected {
    background: #b8efef;
  }

  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #222936;
  }

  small {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #b1b1bf;
  }

  &.selected {
    small {
      color: #222936;
    }

    svg path {
      fill: #222936;
    }
  }
}

.location .dropdown-menu {
  min-width: 8rem;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #ffcd35;
  border-color: #ffcd35;
  background-color: #ffcd35;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  box-shadow: 0 2px 4px 0 rgb(255 205 53 / 0%) !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #ffcd35;
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before,
.custom-radio .custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 2px 4px 0 rgb(255 205 53 / 0%) !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: #ffcd35;
  border-color: #ffcd35;
}

.moreOption {
  min-height: 39px;
  min-width: 39px;
}

.bk-list:hover {
  background: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.location .edit-header,
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-card .card-body {
  border: 1px solid rgba(108, 117, 125, 0.12);
  box-shadow: 0px 4px 24px rgb(0 0 0 / 6%);
  border-radius: 6px;
}

.location .app-card .card-body label,
.app-card .card-body label {
  color: #222936;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.input-group>.input-group-prepend>.input-group-text {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.card .card-header.tab-card-header {
  background: #b8efef;
  padding: 10px 12px;

  .card-title {
    color: #222936;
  }
}

.btn-outline-dark {
  border: 1px solid #222936 !important;
  background-color: transparent;
  color: #222936;
}

.bg-header-css {
  background: #f8f8f8;
  padding: 12px;
}

.card .expenditure-table .table tbody td {
  font-weight: 600;
  font-size: 14px;
  color: #222936;
}

.expenditure-table .card .table tbody td {
  text-align: left;
}

.card .expenditure-table .table tbody td:first-child {
  width: 70%;
}

/* media */

/* Model PopUp */

.modal-header.modal-popup {
  background-color: $primary;
}

.modal-footer.message_box {
  padding: 10px 0;
}

/* Model Popup */

/* For SE User   */

.btn.btn-primary.filter_btn {
  width: 102px;
}

.btn_search_box.projects_table {
  display: flex;

  /* padding: 20px; */
  padding: 10px 9px;
}

.gov_users_table_btn_div {
  width: 100%;
  float: left;
  display: flex;
}

.all_projects_table_btn_div {
  width: 100%;
  float: left;
}

.gov_users_table_btn_div.create_package {
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 16px;
  }
}

.search_div {
  display: flex;
  justify-content: flex-end;
  float: right;
  width: auto;
  height: fit-content;
}

.form-control.search_bar {
  width: auto;
  margin: 0 8px 0 0;

  /* height: auto; */
}

.form-label.filter_label {
  color: #222936;
  font-weight: 600;
  font-size: 12px;
}

.btn.btn-secondary.reset_filter {
  position: absolute;
  right: 20px;
  margin: 5px 5px;
  background-color: transparent !important;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #b8efef !important;
  color: #222936 !important;
}

.work_status .select2-container--default .select2-results__option[aria-selected="true"]::after {
  content: "";
  //   background-image: url("../svg/right.svg");
  width: 24px;
  height: 10px;
  display: inline-flex;
  background-repeat: no-repeat;
  position: relative;
  right: 0;

  /* top: 0; */
  float: right;
  margin: 5px -8px;
}

.select2-container--default .select2-results__option--highlighted {
  background-color: #bbf0f096 !important;
  color: #222936 !important;
}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #222936 !important;
  border-color: #222936 !important;
  border-radius: 5px;
  color: #ffffff !important;
}

.select2-container--default {
  .select2-selection--single:focus {
    border-color: $primary !important;
  }

  &.select2-container--open .select2-selection--single {
    border-color: $primary !important;
    outline: 0;
  }
}

.card .card-header.forest-header {
  border-bottom: 1px solid #ebe9f1;
  margin-bottom: 15px;
  padding: 4px 0 5px;

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #222936;
  }
}

/* SE  se_dashboard*/

.user-online {
  ul {
    display: inline-flex;
    margin: 0 0;
    list-style: none;
    padding: 0 0;
  }

  .card-body {
    padding: 0.5rem;
  }

  ul li {
    margin-right: 58px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: normal;
    color: $dark;
    margin-top: 0;

    .u-info {
      width: 15px;
      height: 15px;
      background: #e9ecef;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 12px;

      &.active {
        background: #28c76f;
      }
    }
  }
}

.bookmark-col .card {
  width: calc(100% - 34px);
  height: 99px;
  padding: 24px 24px;
  justify-content: center;
  border-radius: 19px;
}

.bookmark-info {
  display: flex;
  align-items: center;

  p {
    padding: 0;
    margin: 0 !important;
    line-height: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #222936;
    display: flex;
    align-items: center;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;
  }

  .bookmark-bigNo {
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    color: #b8efef;
    margin: 0 77px 0 auto;
  }
}

.bookmark-bigNo.yellowtxt {
  color: $primary;
}

/* .bookmark-info.bookmark-bigNo.yellowtxt{
  
	  color: $primary;
  
  } */

.bookmark-col img {
  width: 127.56px;
  height: 75.93px;
  position: absolute;
  right: -30px;
  top: 6px;
}

.dashboard-bookmark {
  .card {
    width: calc(100% - 34px);
    height: 99px;
    padding: 24px 24px;
    justify-content: center;
    border-radius: 19px;
  }

  img {
    width: 127.56px;
    height: 75.93px;
    position: absolute;
    right: -30px;
    top: 6px;
  }
}

.bookmark-col svg {
  width: 127.56px;
  height: 75.93px;
  position: absolute;
  right: -30px;
  top: 6px;
}

.dashboard-bookmark svg {
  /* width: 127.56px;
	height: 75.93px; */
  position: absolute;
  right: 0px;

  /* top: 6px; */

  path {
    fill: #000000;
  }
}

.app-progress {
  height: 27px;
  border-radius: 5px;

  .progress-bar.green {
    background-color: #28c76f;
    border-radius: 5px;
  }

  &.progress-bar-primary {
    background-color: rgb(239 239 239 / 60%);
    border: 1px solid #e7e8e9;
  }
}

.three-section .card .card-header {
  border-bottom: 1px solid #e7e8e9;

  h5 {
    color: #222936;
    font-size: 16px;
  }
}

.se_expenditure-card {
  .title-p {
    margin: 0 0;
    font-size: 12px;
  }

  .target-p {
    margin: 0 0;
    font-size: 12px;
    color: #58595b;

    /* opacity: 0.4; */
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }

  li {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 5px;
  }
}

.plant_monitoring_chart_info {
  display: flex;
  justify-content: center;
  flex-direction: column;

  h4 {
    font-weight: 600;
    font-size: 18px;
    color: $dark;
  }
}

.Reports-section p {
  color: #222936;
}

.Reason-section {
  p {
    color: #222936;
  }

  .card-body p {
    display: flex;
    justify-content: space-between;
  }
}

.bk-total-projects .card-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #222936;
}

.government-plant svg {
  width: 20px;
  height: 20px;
  min-width: 23px;
  min-height: 23px;
}

/* For SE User   */

/* App tabs */

.app-tabs .nav-tabs .nav-link {
  color: #82868b;

  &.active {
    position: relative;
    color: $dark;
    background: #b8efef;
    border-radius: 6px;
  }

  &:after {
    background: transparent !important;
  }
}

.card-body.app-tabs ul.nav.nav-tabs {
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: inherit;
}

/* media */

/* scrollbar */

/* width */

::-webkit-scrollbar {
  width: 8px;
}

/* that is currently Firefox-only */

* {
  scrollbar-width: thin;
  scrollbar-color: $primary #f8f8f8;

  &::-webkit-scrollbar {
    width: 7px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f8f8f8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 20px;
  }
}

/* Works on Chrome/Edge/Safari */

/* end scrollbar */

/* login page */

.card .login-info {
  .card-title {
    color: $dark;
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}

.forgot-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #222936;
  cursor: pointer;
}

.auth-login-form {

  .form-label,
  .password-box {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
}

[dir] .auth-login-form .form-check-label.form-label {
  font-size: 14px;
  font-weight: 400;
}

.btn-login {
  padding: 11px 16px;
  margin-top: 32px;
}

[dir] .auth-wrapper .brand-logo {
  display: flex;
  justify-content: center;
  margin: 1rem 0 1rem 0 !important;
}

.hr-border {
  margin-bottom: 32px;
}

[dir] .auth-wrapper {
  &.auth-basic {
    justify-content: right;
  }

  background: url(../images/wide-road-min.jpg) no-repeat;
  background-position: center center;
  object-fit: cover;
  min-width: 100%;
  background-size: 100%;

  background: url(../images/wide-road-min.jpg) no-repeat;
  background-position: center center;
  object-fit: cover;
  min-width: 100%;
  background-size: 100%;

  .auth-inner {
    width: 100%;
    position: relative;
    margin-right: 140px;
    background-color: transparent !important;
  }

  &.auth-basic .auth-inner {
    &:before {
      background-image: url(../images/top.png) !important;
      top: -75px;
    }

    &:after {
      background-image: url(../images/Bottom.png) !important;
      z-index: 0 !important;
      bottom: -76px;
    }
  }
}

/* end login page  */

.ichart {
  max-width: 590px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .apexcharts-series {
    cursor: pointer;
  }
}

/* for EE user  */

.table-title.ee_new_work_title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-bottom: 15px; */

  p {
    /* margin: 24px 0 8px 0; */
    color: #222936;
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    text-align: end;
    margin-bottom: 0;
  }
}

.customSwitch-color {
  color: #222936;
  font-weight: 600;
  font-size: 13px;
}

/* .customSwitchRS .custom-control-input:checked ~ .custom-control-label::before{
	  background-color: $primary;
  }
  .customSwitchRS .custom-control-input ~ .custom-control-label::before{
	  background-color: #3ebdbd;
  } */

.basic_detail_btn {
  margin: 0 24px 24px 24px;
  justify-content: flex-start;

  button {
    padding: 10px;
    min-width: 67px;
  }
}

.basic_tab label {
  color: #222936;
  font-weight: 600;
  font-size: 12px;
}

.btn {
  &.btn-primary.save-btn {
    width: 67px;
    height: 37px;
  }

  &.btn-outline-dark.cancle-btn {
    width: 84px;
    height: 37px;
    margin-right: 8px;

    /* font-size: 10px; */
  }
}

.demo-inline-spacing.checkbox {
  >* {
    margin-top: 0.5rem;
  }

  .custom-control-label {
    font-weight: 400;
    font-size: 14px;
    color: #58595b;
  }
}

.card.forest {
  border: 1px solid rgba(108, 117, 125, 0.12);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
}

/* For EE user */

.form-control {

  &:disabled,
  &[readonly] {
    background-color: #efefef;
    opacity: 1;
  }
}

.expenditure_ee tr th {
  vertical-align: middle;
}

.card-body.app-tabs.dropdowntabs {
  padding: 0;
}

.taluka-action {
  width: 85px;
}

.utility-checkbox {
  margin-right: 16px;
}

/* end EE */

/* forgot-modal */

.modal-open .forgot-modal.modal {
  background: rgb(0 0 0 / 48%);
}

/* data-table drop-down */

div {
  &.dt-button-background {
    background: transparent;
  }

  &.dt-button-collection {
    border: 0;

    /* z-index: 99; */
    max-height: 250px;
    overflow: auto;

    button.dt-button {
      &:active:not(.disabled) {
        background: transparent;
      }

      &.active {
        background: #ffcd35 !important;
      }
    }
  }
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled),
input.dt-button:hover:not(.disabled) {
  border: 0px solid #666;
  background-color: rgba(0, 0, 0, 0.1);
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr="rgba(153, 153, 153, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
}

div {
  &.dt-button-collection button.dt-button {
    text-align: left;
    font-size: 13px;
    background: transparent;
    border: 0;

    &.active:not(.disabled) {
      text-align: left;
      font-size: 13px;
      font-weight: 500;
      background: transparent;
      box-shadow: none;
      border: 0;
    }
  }

  &.dt-buttons .dropdown-toggle .dt-down-arrow {
    display: none;
  }
}

.page-item:first-child:not(.prev-item) .page-link {
  border-radius: 50%;
  max-width: 20px;
}

/*  switch*/

.custom-switch .custom-control-label:after {
  top: 5px;
}

/* end data-table drop-down */

.top-user-info .dropdown-menu .navbar-nav li.nav-item,
.menu-icon .i-open {
  display: none;
}

/* menu icon on sidebar */

.menu-open .menu-icon {
  .i-open {
    display: block;
  }

  .i-menu {
    display: none;
  }
}

.edit-popup-work .modal-content .tab-content {
  position: relative;
  overflow-y: auto;
  max-height: 530px;
}

@media (max-width: 1520px) {
  .menu-expanded .location-block .card .card-header .card-title {
    display: block;
    width: 100%;
    margin-bottom: 13px;
  }
}

@media (max-width: 1366px) {
  /* For SE User   */

  .btn {

    &.btn-outline-primary.projects_btn,
    &.btn-secondary.projects_btn {
      margin-bottom: 5px;
    }
  }

  .location-block .card .card-header .card-title {
    display: block;
    width: 100%;
    margin-bottom: 13px;
  }

  .menu-expanded .abstract-btn {
    margin-top: 5px;
  }
}

@media (max-width: 1200px) {
  .header-navbar.floating-nav {
    width: 30px;
    left: 0;
    float: left;
    margin-left: 12px;
  }

  .top-user-info {
    .navbar-nav li.nav-item {
      display: none;
    }

    .dropdown-menu .navbar-nav {
      li.nav-item {
        margin: 10px 15px 0;
        display: block;

        &.active {
          color: #181818;
        }
      }

      .nav-link {
        color: #181818;
      }
    }
  }

  .menu-icon svg {
    width: 30px;
    height: 30px;
    color: white;
  }

  .main-logo {
    margin-left: 25px;
  }
}

@media (max-width: 1024px) {
  .auth-wrapper {
    background-size: 150% 100%;

    &.auth-basic {
      justify-content: center;
    }

    .auth-inner {
      margin-right: 0;
    }
  }

  .menu-icon svg {
    width: 30px;
    height: 30px;
    color: white;
  }

  .main-logo {
    margin-left: 20px;
  }

  .menu-expanded .abstract-btn {
    margin-top: 0;
  }
}

/* ipad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .location-block .card .card-header .card-title {
    display: block;
    width: auto;
    margin-bottom: 0;
  }
}

@media (max-width: 1000px) {
  .old_completed_work.gov_users_table_btn_div {
    display: block;
    justify-content: space-between;
    margin: 0px 0 10px 0;
  }

  .gov_users_table_btn_div {
    display: flex;
    justify-content: space-between;
    margin: 0px 0 10px 0;
  }

  @media (orientation: landscape) {
    .gov_users_table_btn_div {
      display: block;
      justify-content: space-between;
      margin: 0px 0 10px 0;

      .dt-buttons {
        margin: 10px 0 0px 0;
      }
    }

    .location-block .card .card-header .card-title {
      display: block;
      width: auto;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 814px) {
  .btn_search_box {
    display: block;
  }
}

@media (max-width: 991px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .Reports-section {
    /* margin: 0px 0 10px 0; */
    margin-bottom: 2rem;
  }

  .Reason-section {
    /* margin: 10px 0 0 0; */
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .auth-wrapper {
    background-size: auto;
  }

  .main-title {
    font-size: 15px;
    margin: 0 0;
    line-height: normal;
  }

  .top-user-info {
    /* display: none; */
    position: absolute;
    z-index: 999;
    right: 0;
  }

  /* .avatar.avatar-lg .avatar-content {
		width: 36px;
		height: 36px;
		font-size: 1rem;
	} */

  .avatar.avatar-lg .avatar-content {
    width: 50px;
    height: 50px;
    font-size: 1.714rem;
  }

  .top-user-info {
    .navbar-nav li.nav-item {
      display: none;
    }

    .dropdown-menu .navbar-nav {
      li.nav-item {
        margin: 10px 15px 0;
        display: block;

        &.active {
          color: #181818;
        }
      }

      .nav-link {
        color: #181818;
      }
    }
  }

  .user-login-info {
    font-size: 13px;
  }

  .header-top {
    height: 100px;
    padding: 6px 24px;
  }

  .navbar {
    position: absolute;
  }

  .menu-icon svg {
    width: 30px;
    height: 30px;
    color: white;
  }

  .main-logo {
    margin-left: 0px;
  }

  .btn_search_box {
    display: block;
    padding: 20px;
  }

  div.dataTables_wrapper {
    min-width: auto;
  }

  .modal .modal-css .modal-body {
    padding: 0px 15px;
  }

  .btn_search_box.projects_table {
    display: block;
    text-align: center;
  }

  .search_div {
    width: 100%;
    justify-content: center;
  }

  .form-control.search_bar {
    width: 100%;
  }

  .btn.btn-primary.filter_btn {
    width: 130px;

    /* margin: 5px 0; */
  }

  .Reports-section {
    /* margin: 0px 0 10px 0; */
    margin-bottom: 2rem;
  }

  .Reason-section {
    /* margin: 10px 0 0 0; */
    margin-top: 2rem;
  }

  .table-title.ee_new_work_title p {
    min-width: 105px;
  }

  .user-online ul li {
    display: flex;
    align-items: center;
  }

  .se_expenditure-card li {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 5px;
  }

  /* mobile landscape  */

  /* @media (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1) {
		  .gov_users_table_btn_div {
			  display: block;
  
		  }
		  div.dt-buttons {
			  text-align: start;
		  }
	  } */
}

@media (max-width: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .gov_users_table_btn_div {
    display: block;

    /* text-align: center; */
  }

  div.dt-buttons {
    text-align: start;
  }

  .plant-table {

    .email-btn,
    .msg-btn {
      margin-top: 5px;
    }
  }

  bu.btn.btn-secondary .email-btn {
    /* bu.btn.btn-secondary .email-btn { */

    margin-top: 5px;
  }
}

@media (max-width: 576px) {
  .gov_users_table_btn_div {
    display: block;
    margin: 0px 0 10px 0;
    text-align: center;

    &.create_package {
      flex-direction: column;
      margin: 0 0 0 0;
    }
  }

  .btn_search_box {
    display: block;
  }

  /* .btn.btn-outline-primary.column-visiblity {
		margin-top: 5px;
	} */

  .avatar.avatar-lg .avatar-content {
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }
}

/* @media only screen and (max-width: 480px) { */
@media (max-width: 480px) {
  .main-title {
    font-size: 13px;
  }
}

.dt_footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 20px;
  align-items: center;
}

.dt_pg_info {
  display: flex;
}

div.dataTables_wrapper div {

  &.dataTables_filter label,
  &.dataTables_length label {
    margin: 0 0;
    padding: 0 0;
    display: flex;
    align-items: center;
  }
}

.dropdown-item {

  &:hover,
  &:focus {
    color: $primary;
    text-decoration: none;
    background-color: transparent;
  }
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 0 0;

  .previous {
    margin-right: 5px;
  }

  .next {
    margin-left: 0.3571rem;
  }
}

/* .pagination .page-item.previous {
	  margin-right: 0.3571rem;
  } */

.page-item {
  &.previous~.page-item {
    &:nth-child(2) {
      .page-link {
        border-top-left-radius: 5rem !important;
        border-bottom-left-radius: 5rem !important;
      }

      border-top-left-radius: 12rem !important;
      border-bottom-left-radius: 12rem !important;
    }

    &:nth-last-child(2) {
      .page-link {
        border-top-right-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important;
      }

      border-top-right-radius: 12rem;
      border-bottom-right-radius: 12rem;
    }
  }

  &:last-child:not(.next-item) .page-link,
  &.next .page-link {
    border-radius: 50%;
    max-width: 20px;
  }
}

/* autocomplete css */

.bs-example {
  font-family: sans-serif;
  position: relative;
  margin: 100px;
}

.twitter-typeahead,
.typeahead,
.tt-query,
.tt-hint {
  width: 100%;
}

.typeahead {
  background-color: #ffffff;

  &:focus {
    border: 2px solid $primary;
  }
}

.tt-query {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}

.tt-hint {
  color: #999999;
}

.tt-menu {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-top: 2px;
  padding: 8px 0;
  width: inherit;
  min-width: inherit;
  max-height: 200px;
  overflow-y: auto;
}

.tt-suggestion {
  font-size: 16px;

  /* Set suggestion dropdown font size */
  padding: 3px 20px;

  &.tt-cursor {
    color: #000;
    background-color: $primary;
  }

  &:hover {
    cursor: pointer;
    background-color: $primary;
    color: #000;
  }

  p {
    margin: 0;
  }
}

/* End autocomplete css  */

/* date pick previous report */

.datepick {
  display: flex;
  align-items: center;
}

.all_projects_table_btn_div.logsbtn-head {
  flex-direction: row;
  display: flex;
}

.logs-dd {
  width: 110px;
  margin-right: 5px;

  .select2-selection--single {
    border-color: $primary;
  }

  .select2-dropdown--below {
    width: 200px !important;
    border: 1px solid $primary;
    border-radius: 3px;
  }
}

.selectwidth {
  width: 200px;
}

.logsbuttons button {
  height: 37px;
  margin-top: 24px;
}

.btnPreviousReport {
  width: 344%;
}

.abstract_dateinfo {
  display: flex;

  p {
    color: #222936;
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    text-align: end;
    margin-bottom: 0;
    margin-right: 8px;
  }
}

@media (max-width: 1024px) {
  .abstract_dateinfo {
    display: block;
  }
}

@media (max-width: 768px) {
  .abstract_dateinfo {
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
    }
  }
}

.modal-dialog-centered h1 {
  text-align: center;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #212224;
}

.modal-dialog-centered .bg-transparent {
  height: 0px;
}

.penicon {
  background-color: transparent;
  border: none !important;
  margin-right: 8px;
  padding: 8px;
}

.invalid-feedback {
  color: red;
  font-size: 14px;
}

.pointerEventNone {
  svg {
    pointer-events: none;
  }
}

.bookmark-title {
  cursor: pointer;

  &:hover,
  &:hover p {
    color: #5e50ee;
  }
}

lable.fp-default {
  color: #5e5873;
  font-size: 0.857rem;
}

.match-height>[class*="col"] {
  display: flex;
  flex-flow: column;
}

.dlt-btn {
  button {
    border: 0px !important;

    &:active,
    &:focus {
      background-color: #ffffff00 !important;
    }
  }
}

.custom-input-style,
.custom-input-style .select__control {
  background-color: rgba(184, 239, 239, 0.3) !important;
  color: #222936 !important;
}

.custom-label-new-plant {
  color: #5e5873 !important;
  font-size: 0.857rem !important;
  font-weight: 400 !important;
}

.custom-modal-yellow {
  background-color: #ffcd00 !important;
}

// datatable
.dataTable thead th {
  background-color: #b8efef;
  color: #222936;
  text-align: center;
}

table.dataTable {
  position: relative;

  tbody td {
    &:first-child {
      font-weight: 600;
      font-size: 14px;
      color: #212224;
    }

    /* padding: 19px 17px 20px 16px; */
    padding: 7px 17px 14px 11px !important;
    text-align: center;
    word-break: break-word;
  }

  th {
    border-color: rgb(169 224 224);
    border-bottom: 1px solid rgb(169 224 224);
    text-transform: uppercase;
  }

  thead th.dtfc-fixed-left {
    outline: 1px solid rgb(170 201 201 / 60%);
    border: 0;
    background: #b8efef;
  }

  tbody td.dtfc-fixed-left {
    // border-left: 0;
    // border-right: 0;
    outline: 1px solid #ebe9f1;
    border: 0;
    background: #b8efef;
  }
}

.table-bordered {

  th,
  td {
    border: 1px solid #ebe9f1;
  }
}

[dir] .table-bordered> :not(caption)>* {
  border-color: #a9e0e0;
}

table.dataTable {
  tbody tr> {

    .dtfc-fixed-left,
    .dtfc-fixed-right {
      z-index: 1;
      background-color: white;
    }

    .dtfc-cell {
      position: sticky;
      z-index: 1;
    }
  }

  td,
  th {
    padding: 0.5rem 0.5rem;
  }

  th.dtfc-cell {
    box-shadow: -1px 0px 0px 0px rgba(170, 201, 201, 0.6),
      0px 1px 0px 0px rgba(170, 201, 201, 0.6);
  }

  thead tr> {
    .dtfc-cell::before {
      content: "";
      background: rgb(170 201 201 / 60%) !important;
      position: absolute;
      width: 1px;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  thead tr> {

    .dtfc-fixed-left::before,
    .dtfc-fixed-right::before {
      content: "";
      background: transparent;
      position: absolute;
      width: 1px;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  tbody tr> {

    .dtfc-fixed-left::before,
    .dtfc-fixed-right::before {
      content: "";
      background: transparent;
      position: absolute;
      width: 1px;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

[dir="ltr"] .page-item {

  &.next-item span,
  &.prev-item span {
    display: none;
  }
}

.tablePagination {
  button.page-link {
    width: 32px;
    height: 32.19px;
    background: #f3f2f7;
    color: #6e6b7b;
  }

  .pagination {
    margin: 0 0;
  }
}

[dir] {
  .tablePagination .btn-flat-primary:hover:not(.disabled):not(:disabled) {
    background: #f3f2f7;
  }

  .page-item {
    &.btn-primary {
      border-color: #f3f2f7 !important;
      background-color: #f3f2f7 !important;
      color: #6e6b7b;
    }

    &.active .page-link {
      background-color: $primary !important;
    }

    // &.next-item .page-link:after {
    //   min-width: 32px;
    // }

    // &.prev-item .page-link:before {
    //   min-width: 32px;
    // }

    button.page-link {
      border-radius: 0;
    }
  }
}

.dt_pg_info {
  align-items: center;

  span {
    color: #5e5873;
    font-size: 0.857rem;
  }
}

.dt_pg_select {
  margin: 0 7px;
}

.p-40 {
  padding: 0.4rem !important;
}

.visibility-Option {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: 5px;
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 12px;
  text-align: left;

  .active {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.vb-drop {
  max-height: 250px;
  overflow: auto;
  z-index: 99;
}

.dt-action-button {
  justify-content: space-between;
  align-items: center;
}

.btn-outline-primary.dropdown-toggle::after {
  background-image: url(../../assets/images/dropdownaerrow.svg);
}

// start datepicker css
.custom-datepicker-popper {
  .react-datepicker {
    &.custom-datepicker-calendar {
      background: #fff;
      // border-color: #0b1e38;
      border: 1px solid #ddd;
      box-shadow: none;
      min-width: 300px;
      font-size: 1rem;

      .react-datepicker__header {
        text-align: center;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        padding: 8px 0;
      }

      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled,
      .react-datepicker__year-text--disabled {
        color: #656d74 !important;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #000;
      }

      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        background-color: #ffcd35;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: #000;
        font-weight: bold;
        font-size: 0.944rem;
        margin-bottom: 10px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        border-radius: 0.3rem;
        background-color: #ffcd35;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 2.7rem;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: #ffcd35;
      }
    }
  }

  select {
    word-wrap: normal;
    background: #efefef;
    border: 0;
    padding: 5px 10px;
    border-radius: 3px;
    color: #000;
  }
}

.react-datepicker-popper.custom-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  border-top-color: #efefef !important;
}

.react-datepicker-popper.custom-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper.custom-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-top-color: #efefef !important;
}

.react-datepicker-popper.custom-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #efefef !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #efefef !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  left: -15px !important;
}

// END Date

.autocomplete-container .suggestions-list {
  max-height: 250px !important;
  overflow-y: auto;
}

svg.User-icon {
  height: 20px !important;
  width: 20px !important;
}

.columnSort {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .dataSort {
    position: absolute;
    width: 14px;
    height: auto;
    right: 0;

    // bottom: 0;
    // top: -4px;
    svg {
      width: 14px;
      height: auto;
      min-height: 12.9px;
      // display: flex;
    }
  }
}

.Reports-section,
.Reason-section {
  p {
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
}

.new-work-date {
  .react-datepicker-wrapper {
    display: block !important;
  }
}

.canvas-main-body {
  width: 30rem;

  .for-offcanvas {
    background-color: #f8f8f8;
    padding: 6px 1.4rem !important;

    .offcanvas-title {
      width: 100% !important;
    }
  }

  .form-custom-input {
    label {
      color: #222936;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }

    .react-select {
      .select__value-container {
        .css-1rhbuit-multiValue.select__multi-value {
          background: #222936 !important;
          border-color: #222936 !important;
          border-radius: 5px;
          color: #ffffff !important;
          margin-bottom: 2px;
        }
      }
    }
  }
}

.sticky-header thead {
  position: sticky;
  top: -1px;
  background-color: #fff;
  /* Add background color if needed */
  z-index: 2;
  /* Adjust z-index as needed */
}

.scrollable-body {
  max-height: 400px;
  /* Set the desired fixed height for the scrollable tbody */
  overflow-y: auto;
}

.table-data-click {
  color: #212224;
  cursor: pointer;

  &:hover {
    color: #5e50ee;
  }
}

.filter-btn-width {
  width: 102px;
}

.autocomplete-container .autocomplete-search.gujarati-bg {
  background: rgba(184, 239, 239, 0.3);
}

.column_visibility {
  .bg-white.btn-primary:focus {
    background-color: transparent !important;
  }
}

.has-sub.sidebar-group-active {
  li {
    a {
      svg {
        margin-right: 1.38rem;
        height: auto;
        width: 15px;
      }
    }
  }
}

// For table header font size fix
.dataTable.table-bordered {
  thead {
    tr {
      .columnSort {
        font-size: 11.998px !important;
        font-weight: 700;
      }
    }
  }
}

.bk-list-info {
  h6 {
    word-break: break-all;
  }
}

.ui-loader.show .overlay {
  background-color: rgb(254 255 255 / 43%) !important;
  z-index: 11;
}

.ui-loader .loader {
  z-index: 13;
}

.react-select {
  .select__value-container {
    .css-1rhbuit-multiValue.select__multi-value {
      background: #222936 !important;
      border-color: #222936 !important;
      border-radius: 5px;
      color: #ffffff !important;
      margin-bottom: 2px;
    }
  }
}

.DatePicker-w100 {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.celllll {
  &:hover {
    color: #5e50ee !important;
  }
}

.dtfc-cell.cell-hover {
  &:hover {
    cursor: pointer;
    background-color: #b8efef;
  }
}

.edit-work-modal .tab-content {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.edit-work-modal .card {
  background-color: transparent;
  box-shadow: none !important;
}

.nodata-loading-div {
  border: 1px solid #ebe9f1;
  border-right: 0;
}

.nodata-loading {
  text-align: center;
  font-weight: bold;
  margin: 10px;
  height: auto;
  font-size: 14px;
}

.expselet .select__menu {
  z-index: 3;
}

.LatitudeLongitude-Tabs .invalid-feedback {
  font-size: 10.5px;
}

// .dt_footer .dt_pg_info .select__menu{ z-index: 11111;
.custom-style-input {
  padding: 0.571rem 1rem !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #d8d6de !important;
  border-radius: 0.357rem !important;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    box-shadow: 0 3px 10px 0 rgba(27, 149, 255, 0.1) !important;
    border-color: #ffcd00 !important;
    outline: none;
  }
}

.work-title {
  font-weight: 600;
  text-decoration: underline;
  color: #000000;
}

.dropdown-user button {
  background-color: transparent !important;
  border-color: transparent !important;

  &:focus {
    background-color: transparent !important;
    border: none;
  }
}